<template>
  <div>
    <div class="cat-grid">
      <b-card
        v-for="(card, index) in cards"
        :key="index"
        v-if="card.condition !== false"
        @click="card.onClick"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2 name text-center">{{ card.name }}</div>
          <i :class="`${card.icon} fa-3x mb-50`"></i>
        </div>
      </b-card>
    </div>

    <landing-page-modal
      :slug="currentEstablishment.slug"
      :establishment="currentEstablishment"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import LandingPageModal from "./LandingPageModal.vue"

export default {
  components: {
    LandingPageModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
    }
  },
  computed: {
    ...mapGetters("establishments", ["currentEstablishment"]),
    emidaABalance() {
      let emidaABalance = 0
      if (this.currentEstablishment.id) {
        this.currentEstablishment.accountings.forEach((account) => {
          if (account.account_type === "emida_a") {
            emidaABalance = account.balance
          }
        })
      }
      return emidaABalance
    },
    emidaBBalance() {
      let emidaBBalance = 0
      if (this.currentEstablishment.id) {
        this.currentEstablishment.accountings.forEach((account) => {
          if (account.account_type === "emida_b") {
            emidaBBalance = account.balance
          }
        })
      }
      return emidaBBalance
    },

    cards() {
      return [
        {
          name: "Proveeduría",
          icon: "fas fa-truck",
          condition: this.userData.role_name === "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-proveeduria",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Sucusarles",
          icon: "fas fa-store",
          condition: this.userData.role_name !== "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-sucursales",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Clientes",
          icon: "fas fa-user-circle",
          condition: this.userData.role_name !== "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-clientes",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Proveedores",
          icon: "fas fa-truck",
          condition:
            (this.userData.role_name === "admin" ||
              this.userData.role_name === "establishment_admin") &&
            this.currentEstablishment.establishment_type !==
              "corporate_education",
          onClick: () =>
            this.$router.push({
              name: "establishment-proveedores",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Empleados",
          icon: "fas fa-users",
          condition:
            this.userData.role_name !== "establishment_presale_clerk" &&
            this.userData.role_name !== "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-empleados",
              params: { id: this.$route.params.id },
            }),
        },

        {
          name: "Emida",
          icon: "fas fa-coins",
          condition: this.userData.role_name === "admin",
          onClick: () =>
            this.$router.push({
              name: "establishment-emida",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Facturama",
          icon: "fas fa-file-invoice-dollar",
          condition: this.userData.role_name === "admin",
          onClick: () =>
            this.$router.push({
              name: "establishment-facturama",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Saldos",
          icon: "fas fa-coins",
          condition:
            this.userData.role_name !== "establishment_presale_clerk" &&
            this.userData.role_name !== "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-balances",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Facturas",
          icon: "fas fa-file-invoice",
          dataTour: "facturas",
          condition:
            this.userData.role_name !== "establishment_presale_clerk" &&
            this.userData.role_name !== "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-facturas",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Pagos electrónicos",
          icon: "fas fa-credit-card",
          dataTour: "stripe",
          condition:
            this.userData.role_name !== "establishment_presale_clerk" &&
            this.userData.role_name !== "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-stripe",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Planes",
          icon: "fas fa-calendar-check",
          dataTour: "planes",
          condition:
            this.userData.role_name !== "establishment_presale_clerk" &&
            this.userData.role_name !== "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-planes",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Tienda en linea",
          icon: "fas fa-shopping-cart",
          condition:
            this.userData.role_name !== "establishment_presale_clerk" &&
            this.userData.role_name !== "establishment_supplier",
          onClick: () => this.handleShowLandingPageModal(),
        },
        {
          name: "Reporte de ventas",
          icon: "fas fa-chart-line",
          onClick: () =>
            this.$router.push({
              name: "establishment-sucursales",
              params: { id: this.$route.params.id },
              query: { tab: "reporte" },
            }),
        },
        {
          name: "Ventas en línea",
          icon: "fas fa-list",
          onClick: () =>
            this.$router.push({
              name: "establishment-sucursales",
              params: { id: this.$route.params.id },
              query: { tab: "ventas" },
            }),
        },
        {
          name: "Administrar inventario",
          icon: "fas fa-dolly",
          onClick: () =>
            this.$router.push({
              name: "establishment-sucursales",
              params: { id: this.$route.params.id },
              query: { tab: "inventario" },
            }),
        },
        {
          name: "Comprar inventario",
          icon: "fas fa-truck",
          onClick: () =>
            this.$router.push({
              name: "establishment-sucursales",
              params: { id: this.$route.params.id },
              query: { tab: "comprar-inventario" },
            }),
        },
        {
          name: "Compras pendientes de pago",
          icon: "fas fa-clipboard-list",
          onClick: () =>
            this.$router.push({
              name: "establishment-pending-orders",
              params: { id: this.$route.params.id },
            }),
        },
        {
          name: "Pedidos automáticos",
          icon: "fas fa-shipping-fast",
          onClick: () =>
            this.$router.push({
              name: "automatic-restocking-view",
              params: { id: this.$route.params.id },
            }),
          condition:
            this.userData.role_name === "admin" ||
            this.userData.role_name === "establishment_admin",
        },
        {
          name: "Configuración",
          icon: "fas fa-tools",
          condition:
            this.userData.role_name !== "establishment_presale_clerk" &&
            this.userData.role_name !== "establishment_supplier",
          onClick: () =>
            this.$router.push({
              name: "establishment-configuracion",
              params: { id: this.$route.params.id },
            }),
        },
      ]
    },
  },
  methods: {
    handleShowLandingPageModal() {
      this.$bvModal.show("landinPageModal")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}

.name {
  text-align: center; /* Centra el texto horizontalmente */
  display: flex; /* Usamos flexbox para centrar también verticalmente si es necesario */
  justify-content: center;
  align-items: center;
  white-space: normal; /* Permite que el texto se divida en líneas */
  word-wrap: break-word; /* Asegura que se ajuste correctamente si hay palabras largas */
}

.cat-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 1rem;
  font-size: 22px;
  font-weight: 800;
  height: 100%;
  cursor: pointer;

  @media screen and (max-width: 1800px) {
    grid-template-columns: repeat(7, 1fr);
    font-size: 18px;
  }

  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(6, 1fr);
    font-size: 18px;
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
    font-size: 18px;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    font-size: 20px;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
    font-size: 18px;
  }

  @media screen and (max-width: 968px) {
    grid-template-columns: repeat(4, 1fr);
    font-size: 15px;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(2, 1fr);
    font-size: 12px;
  }

  @media screen and (max-width: 300px) {
    grid-template-columns: repeat(1, 1fr);
    font-size: 15px;
  }

  .cat {
    overflow: hidden;
    margin-bottom: 1px;

    &:hover {
      transform: scale(1.05);
      transition: transform 0.2s ease-in-out;
    }
  }

  .icon {
    width: 50px;
    height: 50px;
  }
}
</style>
